.heading {
  color: white;
  opacity: 0.9;
}

.text {
  color: white;
  opacity: 0.9;
  font-size: 1.3rem;
  text-align: center;
  margin: 0.5rem;
}

.redirectButton {
  font-weight: 400;
  padding: 0.5625rem 1rem;
  font-weight: bold;
  line-height: 1.125;
  border-radius: 5px;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  vertical-align: middle;
  outline: none;
  touch-action: manipulation;
  white-space: nowrap;
  border-color: var(--dark);
  background: unset;
  background-color: transparent;
  color: white;
  font-size: 1.3rem;
  border: 2px solid white;
  margin: 1rem;

  &:focus {
    outline: none;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
}
